import { Card } from "antd";
import "./styles.scss";

const NoData = () => {
  return (
    <Card>
      <div className="NoData-noDataText">
        <div className="NoData-Text">No Data Found</div>
      </div>
    </Card>
  );
};
export default NoData;

import { IconType } from "react-icons";
import { CgUnavailable } from "react-icons/cg";
import { GiProgression } from "react-icons/gi";
import { MdDashboard, MdOutlineWork } from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { FaNetworkWired } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";

import { FaUsers } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { IoCalendarOutline } from "react-icons/io5";

import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoPeopleOutline } from "react-icons/io5";

import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { CiGrid42 } from "react-icons/ci";

function DynamicIcon(props: any) {
  type IconName =
    | "MdDashboard"
    | "TbBrandBooking"
    | "CgUnavailable"
    | "GiProgression"
    | "MdOutlineWork"
    | "FaNetworkWired"
    | "IoPeopleOutline"
    | "GrTransaction"
    | "HiOutlineOfficeBuilding"
    | "IoCalendarOutline"
    | "LiaFileInvoiceDollarSolid"
    | "CiGrid42"
    | "IoMdContacts";

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }

  function Icon({ iconName, size = 25, color = "#0a2f35" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      MdDashboard: MdDashboard,
      TbBrandBooking: TbBrandBooking,
      CgUnavailable: CgUnavailable,
      GiProgression: GiProgression,
      MdOutlineWork: MdOutlineWork,
      FaNetworkWired: FaNetworkWired,
      IoMdContacts: IoMdContacts,
      IoPeopleOutline: IoPeopleOutline,
      HiOutlineOfficeBuilding: HiOutlineOfficeBuilding,
      IoCalendarOutline: IoCalendarOutline,
      LiaFileInvoiceDollarSolid: LiaFileInvoiceDollarSolid,
      GrTransaction: GrTransaction,
      CiGrid42: CiGrid42,
    };

    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable";
    }

    const IconComponent = icons[iconName];

    return <IconComponent size={size} />;
  }

  return <Icon iconName={props.icon} size={props.size} color={props.color} />;
}

export default DynamicIcon;

import {
  CalendarOutlined,
  DollarOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Statistic } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { API } from "../../../config/Api";
import { POST } from "../../../utils/apiRequest";

const Transactions = (props: any) => {
  const [transaction, setTransaction] = useState<any>([]);
  const [totalDueAmount, setTotalDueAmount] = useState<number>(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState<number>(0);

  useEffect(() => {
    getTransaction();
  }, [props?.id]);

  const getTransaction = async () => {
    try {
      let obj = {
        office_id: null,
        user_id: props?.id,
        subscription_id: null,
        payment_method: null,
        period: null,
        value: null,
      };
      const response: any = await POST(
        API.TRANSACTIONS + "?order=ASC&page=1&take=100",
        obj
      );
      if (response?.status) {
        setTransaction(response?.data?.entities);
        setTotalDueAmount(response?.data?.due);
        setTotalPaidAmount(response?.data?.paid);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const isPaid = totalPaidAmount >= totalDueAmount;
  return (
    <div>
      <div className="col-5">
        <Card
          title="Payment Summary"
          style={{
            backgroundColor: isPaid ? "#f6ffed" : "#fff2f0",
          }}
        >
          <div className="transaction-box1">
            <Statistic
              title="Due Amount"
              value={totalDueAmount}
              prefix="$"
              valueStyle={{ color: isPaid ? "green" : "red" }}
            />
            <Statistic
              title="Paid Amount"
              value={totalPaidAmount}
              prefix="$"
              valueStyle={{ color: isPaid ? "green" : "inherit" }}
            />
          </div>
        </Card>
      </div>
      <br />
      <div className="col-5">
        {transaction?.map((item: any, index: number) => (
          <Card key={index}>
            <div className="transaction-box2">
              <div>
                <UserOutlined /> &nbsp;
                <span style={{ fontWeight: "bold" }}>
                  {item?.user_name || "N/A"}
                </span>
              </div>
              <div>
                <CalendarOutlined />
                &nbsp;
                <span>
                  {moment(item?.createdAt).format("DD-MM-YYYY h:mm A")}
                </span>
              </div>
            </div>

            <div className="transaction-box2">
              <span>
                <SolutionOutlined /> &nbsp; {item?.by || "N/A"}
              </span>
              <span style={{ fontWeight: "bold" }}>
                Amount : {item?.amount?.toLocaleString() || "N/A"}
              </span>
            </div>

            <hr />

            <div className="transaction-box1">
              <div>
                <DollarOutlined /> &nbsp;
                <span>Paid Amount</span>
              </div>
              <span style={{ fontWeight: "bold" }}>
                ₹{item?.paid_mount?.toLocaleString() || "N/A"} /-
              </span>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Transactions;

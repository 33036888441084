import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import CountryCode from "../../utils/countryCodes.json";
import { FindDateRange } from "../../utils/dateRange";
import "./styles.scss";

function CreateBooking() {
  const location = useLocation();
  const booking = location.state?.booking;
  const navigate = useNavigate();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState<any>([]);
  const [selectedSub, setSelectedSub] = useState<any>(null);
  const [start_date, setStart_date] = useState<any>(null);
  const [end_date, setEnd_date] = useState<any>(null);
  const [paid_amount, setPaidAmount] = useState<number>(0);
  const [price_amount, setPriceAmount] = useState<number>(0);
  const [duration, setDuration] = useState<any>(null);
  const [durationType, setDurationType] = useState<any>(null);

  const [accountType, setAccountType] = useState<string | null>(null);
  const User = useSelector((state: any) => state?.User?.user);

  useEffect(() => {
    getDetails();
  }, []);

  const [form] = Form.useForm();

  const params = useParams();

  const getDetails = async () => {
    try {
      const details: any = await GET(
        `${API.SUBCRIPTION_OFFICE}${User?.office?.id}`,
        {}
      );
      if (details?.status) {
        setSubscription(details?.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  const handleAccountTypeChange = (value: string) => {
    setAccountType(value);
  };

  const SelectSubScription = async (value: any) => {
    try {
      setSelectedSub(value);
      let duration_type = value.duration_type;
      let duration = value.duration;
      setDuration(duration);
      setDurationType(duration_type);
      let ranges: any = await FindDateRange(duration_type, duration);
      setStart_date(ranges?.start);
      setEnd_date(ranges?.end);
      setPaidAmount(value?.price);
      setPriceAmount(value?.price);
    } catch (err) {
      console.log("err", err);
    }
  };
  const ChangeSubDate = (start: any, end: any, type: any) => {
    try {
      setStart_date(start);
      setEnd_date(end);

      const duro = duration ? duration : 0;

      if (type === 1) {
        let endDate = moment(start).add(duro, durationType);
        setEnd_date(endDate);
        form.setFieldsValue({ endDate: endDate });
      } else {
        let startDate = moment(end).subtract(duro, durationType);
        setStart_date(startDate);
        form.setFieldsValue({ startDate: startDate });
      }
    } catch (err) {
      console.log("Error in ChangeSubDate:", err);
    }
  };

  const onFinish = async (val: any) => {
    console.log("val");
    console.log(val);
    console.log("val");

    try {
      setIsLoading(true);

      const payload = {
        type: val?.type,
        status: selectedSub?.name ? true : false,
        office_name: User?.office?.name,
        office_id: User?.office?.id,
        first_name: val?.first_name,
        last_name: val?.last_name,
        mobile_number: val?.prefix1 + val?.mobile_number,
        mobile_number2: val?.prefix2 + val?.mobile_number2,
        birth_date: val?.dob,
        place: val?.address,
        photo: "",
        id_proof: "",
        weight: val?.weight,
        height: val?.height,
        blood: val?.blood,
        gender: val?.gender,
        prefix: val?.prefix,
        suffix: val?.suffix,
        register_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        start_date: moment(start_date ? start_date : new Date()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ),
        end_date: moment(end_date ? end_date : new Date()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ),
        paid_amount: Number(paid_amount) >= 0 ? Number(paid_amount) : 0,
        amount: price_amount,
        subscription_name: selectedSub?.name,
        subscription_id: selectedSub?.id,
        email: val?.email,
        password: accountType === "trainer" ? "" : null,
        payment_method: "",
        gates: val?.gate,
      };

      const response: any = await POST(API.USER_ADD, {
        ...payload,
        by: `${User?.user?.first_name} ${User?.user?.last_name}`,
      });

      if (response?.status) {
        notification.success({
          message: "Success",
          description:
            response?.message || "An error occurred while adding the user.",
        });
        navigate("/Auth/userDetails");
      } else {
        notification.error({
          message: "Error",
          description:
            response?.message || "An error occurred while adding the user.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        message: "Error",
        description: "An unexpected error occurred.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getUser = async () => {
    try {
      let api = API.USER_DETAILS + params?.id;
      const response: any = await GET(api, null);
      if (response?.status) {
        let data = response?.data;

      
        setIsLoading(false);
      } else {
        console.error(response?.message);
        // navigation.navigate('Users');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    if (params?.id) {
      getUser();
    }
  }, []);

  return (
    <div>
      {contextHolder}
      <PageHeaders
        title={params?.id ? "Update User" : "Create User"}
        breadcrumb={params?.id ? "Update User" : "Create User"}
      />
      <div className="screenBox">
        <Card>
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            initialValues={{
              prefix1: "+91", 
              prefix2: "+91", 
            }}
          >
            <Row>
              <h5 className="user-heading">Basic Details</h5>
              <Col md={4}>
                <Form.Item
                  name="prefix"
                  label="Prefix"
                  initialValue={User?.office?.prefix}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item name="last_name" label="Last Name">
                  <Input placeholder="Eg: John" />
                </Form.Item>
                <Form.Item name="email" label="Email">
                  <Input type="email" placeholder="Eg: john@gmail.com" />
                </Form.Item>
                <Form.Item name="blood" label="Blood Group">
                  <Select placeholder="Choose Blood Group">
                    {["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"].map(
                      (blood, idx) => (
                        <Select.Option value={blood} key={idx}>
                          {blood}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[{ required: true, message: "Choose a Address" }]}
                >
                  <TextArea />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="suffix" label="Admission Number">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="mobile_number"
                  label="Primary Number"
                  rules={[
                    { required: true, message: "Enter Primary Number" },
                    {
                      pattern: /^\d{10}$/,
                      message: "Mobile number must be exactly 10 digits",
                    },
                  ]}
                >
                  <Input
                    addonBefore={
                      <Form.Item name="prefix1" style={{ marginBottom: -1 }}>
                        <Select
                          defaultValue="+91"
                          size="small"
                          showSearch
                          style={{ width: 75 }}
                        >
                          {CountryCode.map((item, idx) => (
                            <Select.Option value={item.dial_code} key={idx}>
                              {item.dial_code}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    }
                    placeholder="Primary Mobile Number"
                  />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[{ required: true, message: "Choose a Gender" }]}
                >
                  <Select placeholder="Choose a Gender">
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="weight" label="Weight (KG)">
                  <Input placeholder="Eg: 60" />
                </Form.Item>
                <Form.Item name="userImage" label="User Image">
                  <Input />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="first_name" label="First Name">
                  <Input placeholder="Eg: John" />
                </Form.Item>
                <Form.Item
                  name="mobile_number2"
                  label="Secondary Mobile"
                  rules={[
                    {
                      pattern: /^\d{10}$/,
                      message: "Mobile number must be exactly 10 digits",
                    },
                  ]}
                >
                  <Input
                    addonBefore={
                      <Form.Item name="prefix2" style={{ marginBottom: -1 }}>
                        <Select
                          defaultValue="+91"
                          size="small"
                          showSearch
                          style={{ width: 75 }}
                        >
                          {CountryCode.map((item, idx) => (
                            <Select.Option value={item.dial_code} key={idx}>
                              {item.dial_code}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    }
                    placeholder="Secondary Mobile Number"
                  />
                </Form.Item>
                <Form.Item name="dob" label="Date of Birth">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item name="height" label="Height (Cm)">
                  <Input placeholder="Eg: 170" />
                </Form.Item>
                <Form.Item name="userID" label="User ID">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <h5 className="user-heading">Subscription Details</h5>
              <Col md={4}>
                <Form.Item name="subscription" label="Subscription Plan">
                  <Select
                    placeholder="Choose a Subscription Plan"
                    onChange={(value, option) => {
                      SelectSubScription(option);
                    }}
                  >
                    {subscription?.map((item: any) => (
                      <Select.Option
                        key={item?.id}
                        id={item?.id}
                        name={item?.name}
                        price={item?.price}
                        duration_type={item?.duration_type}
                        duration={item?.duration}
                      >
                        {item.name} - {item.duration} {item.duration_type} - ₹
                        {item.price}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="subscriptionName" hidden>
                  <Input />
                </Form.Item>

                <Form.Item
                  name="paymentMethod"
                  label="Payment Method"
                  rules={[{ required: true, message: "Choose Payment Method" }]}
                >
                  <Select placeholder="Choose Payment Method">
                    {["BANK", "UPI", "CARD", "CASH"].map((method, idx) => (
                      <Select.Option value={method} key={idx}>
                        {method}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="startDate" label="Start Date">
                  <DatePicker
                    style={{ width: "100%" }}
                    value={start_date}
                    onChange={(value) => ChangeSubDate(value, end_date, 1)}
                  />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="endDate" label="End Date">
                  <DatePicker
                    style={{ width: "100%" }}
                    value={end_date}
                    onChange={(value) => ChangeSubDate(start_date, value, 2)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <h5 className="user-heading">GateWays</h5>
              <Col md={4}>
                <Form.Item
                  name="gate"
                  label="GateWay"
                  rules={[{ required: true, message: "Choose GateWay" }]}
                >
                  <Select placeholder="Choose GateWay">
                    {User?.office?.devices?.map((item: any, idx: number) => (
                      <Select.Option value={item?.device_id} key={idx}>
                        {item?.device_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <h5 className="user-heading">Account Type</h5>
              <Col md={4}>
                <Form.Item
                  name="type"
                  label="Account Type"
                  rules={[
                    { required: true, message: "Choose an Account Type" },
                  ]}
                >
                  <Select
                    placeholder="Choose an Account Type"
                    onChange={handleAccountTypeChange}
                  >
                    <Select.Option value="user">User</Select.Option>
                    <Select.Option value="trainer">Trainer</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {accountType === "user" && (
                <Col md={4}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: "Enter a Password" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row>
              <Col></Col>
              <Col sm={4}>
                <Button size="large" danger block onClick={() => navigate(-1)}>
                  Close
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {params?.id ? "Update" : "Submit"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default CreateBooking;

import { Card } from "antd";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { GET } from "../../utils/apiRequest";

const TransactionDetails = () => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    try {
      const api = "";
      let response: any = await GET(api,"");
      if (response?.status) {
        const arr: any = [];
        arr.push(response.data);
        setData(arr);
        setIsLoading(false);
      } else {
        console.log("some thing went wrong");
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err === >", err);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PageHeaders title={"Trnsaction Details"} backButton={true} />
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <Table bordered hover>
              <thead>
                <tr style={{ backgroundColor: "#08493e", color: "#fff" }}>
                  <th className="table-th">ID</th>
                  <th className="table-th">Booking ID</th>
                  <th className="table-th">User Name</th>
                  <th className="table-th">E-mail</th>
                  <th className="table-th">Phone No.</th>
                  <th className="table-th">Payment Method</th>
                  <th className="table-th">Count</th>
                  <th className="table-th">Paid Amount</th>
                  <th className="table-th">Grand Total</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td>{item?.bookingId}</td>
                    <td>{item?.userName}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.paymentMethod}</td>
                    <td>{item?.count}</td>
                    <td>{item?.paidAmount}</td>
                    <td>{item?.grandTotal}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </div>
    </div>
  );
};

export default TransactionDetails;

import { Pagination, Table } from "antd";
import moment from "moment";

function TransactionDataTable({
  data,
  page,
  pageSize,
  total,
  onPageChange,
}: any) {
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      width:50,
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width:120,
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_mount",
      key: "paid_mount",
      width:120,
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      width:150,
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width:120,
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width:120,
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={(record) => record?.id || Math.random()}
        scroll={{ x: true }}
      />
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}
      >
        <Pagination
          current={page}
          pageSize={pageSize}
          total={total}
          showSizeChanger
          showTotal={(total) => `Total ${total} Transactions`}
          onChange={onPageChange}
          responsive
        />
      </div>
    </>
  );
}

export default TransactionDataTable;

import { Badge, Image, Tag } from "antd";
import moment from "moment";
import { FaRegUserCircle } from "react-icons/fa";
import { GiPlainCircle } from "react-icons/gi";
import NoData from "../../components/noData";
function ExpiredUser(props: any) {
  const isExpired = (date: string) => {
    try {
      let end_date = moment(date);
      let today = moment();
      return today.isSameOrAfter(end_date, "D");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="dashboard-Txt2">
        <div>Recently Expired</div>
        <div>{props?.data?.length}</div>
      </div>
      {props?.data?.length ? (
        props?.data.map((item: any, index: number) => (
          <Badge.Ribbon
            key={index}
            text={item?.type === "trainer" ? "Trainer" : ""}
            color={item?.type === "trainer" ? "#1b6ad5" : "transparent"}
          >
            <div className="Dashboard-booking">
              <div className="Dashboard-cardBox1">
                {item?.photo ? (
                  <Image src={item?.photo} alt="" style={{ height: "60px" }} />
                ) : (
                  <FaRegUserCircle
                    style={{
                      fontSize: "40px",
                      borderRadius: 5,
                      color: "grey",
                    }}
                  />
                )}
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  {item?.first_name} {item?.last_name}
                </div>
                <div>{item?.admisson_id}</div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {`${
                      isExpired(props?.item?.end_date) ? "Expired" : "Expire"
                    } on ${moment(props?.item?.end_date).format("DD/MM/YYYY")}`}
                  </div>
                  <div>
                    <GiPlainCircle
                      color={
                        item?.HoldResume?.id
                          ? "orange"
                          : item?.status
                            ? "#31d467"
                            : "red"
                      }
                    />
                  </div>
                </div>
                <Tag color="">{item?.subscription_name || ""}</Tag>
              </div>
            </div>
          </Badge.Ribbon>
        ))
      ) : (
        <div>
          <NoData />
        </div>
      )}
    </>
  );
}

export default ExpiredUser;

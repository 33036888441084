import { Pagination, Table } from "antd";
import moment from "moment";

function SubscriptionDataTable(props: any) {
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Duration Type",
      dataIndex: "duration_type",
      key: "duration_type",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}

      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) => `Total ${props?.meta?.total_count} Subscriptions`}
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
          responsive
        />
      </div>
    </>
  );
}

export default SubscriptionDataTable;

import { Button, Tag } from "antd";
import Clock from "react-live-clock";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slice/userSlice";

function LayoutHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const greetBasedOnTime = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour < 16) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };
  const handleClick = () => {
    dispatch(logout())
    navigate("/");
  };
  return (
    <div className="dashboard-Header">
      <div>{greetBasedOnTime()}!</div>
      <div style={{ flex: 1 }} />
      <Tag color="blue">ADMIN</Tag>
      <Button style={{ width: "120px" }} size="small">
        <Clock format={"h:mm:ss A"} ticking={true} />
      </Button>
      <Button  size="small"  onClick={() => handleClick()}>LOGOUT</Button>
    </div>
  );
}

export default LayoutHeader;

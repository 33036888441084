import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  notification,
  Select,
  Space
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import HorizontalLineLoader from "../components/loader";
import TransactionDataTable from "./dataTable";
import DATE_RANGE_OPTIONS from "../../utils/dateRange.json";

function TransactionScreen() {
  const [form] = useForm();
  const User = useSelector((state: any) => state?.User?.user);
  const id = User?.office?.id;
  const [notificationApi, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [meta, setMeta] = useState({ itemCount: 0 });

  const [searchState, setSearchState] = useState({
    query: "",
    filterOption: "all",
    sortOrder: "DESC",
    page: 1,
    pageSize: 20,
  });
  const [showRangePicker, setShowRangePicker] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState("today");
  const { RangePicker } = DatePicker;

  useEffect(() => {
    fetchTransactions();
  }, [page, pageSize]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const url = `${API.LIST_TRANSACTIONS}?order=DESC&page=${page}&take=${pageSize}`;
      const body = { office_id: id.toString() };
      const response: any = await POST(url, body);

      if (response?.status) {
        setData(response?.data?.entities || []);
        setMeta(response?.meta || { total_count: 0 });
      } else {
        message.error("Oops! Something went wrong.");
      }
    } catch (error) {
      message.error("Error fetching transactions.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number, newPageSize: number) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };
  const clearFilters = () => {
    setSearchState({
      query: "",
      filterOption: "all",
      sortOrder: "DESC",
      page: 1,
      pageSize: 20,
    });
    form.resetFields();
    setSelectedDateRange("today");
    setShowRangePicker(false);
  };

  const dateRangeMenu = (
    <Menu
      items={DATE_RANGE_OPTIONS.map(range => ({
        key: range.id.toString(),
        label: range.text,
        onClick: () => handleMenuClick(range.value),
      }))}
    />
  );
  const handleDateRangeChange = (dates: any) => {
    if (!dates) {
      setShowRangePicker(false);
      setSelectedDateRange("today");
    }
  };
  const handleMenuClick = (rangeValue: string) => {
    setSelectedDateRange(rangeValue);
    if (rangeValue === "") {
      // Show RangePicker when "Custom Date" is selected
      setShowRangePicker(true);
    } else {
      setShowRangePicker(false);
    }
  };
  
  return (
    <div>
      {contextHolder}
      <PageHeaders title="Transaction" breadcrumb="Transaction">
        <Form form={form} onValuesChange={fetchTransactions} layout="inline" >
        <div className="d-flex flex-column flex-wrap flex-sm-row gap-2">
          <Form.Item name="query" noStyle>
            <Input
              style={{ width: 200 }}
              placeholder="Search name or phone or emil etc ..."
              allowClear
              suffix={<CiSearch size={16} color="#000" />}
            />
          </Form.Item>
          {!showRangePicker && (
              <Form.Item>
                <Dropdown overlay={dateRangeMenu} trigger={["click"]}>
                  <Button>
                    <Space>
                      {DATE_RANGE_OPTIONS.find((r) => r.value === selectedDateRange)?.text || "Today"}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Form.Item>
            )}
            {showRangePicker && (
              <Form.Item name="date" noStyle>
                <RangePicker onChange={handleDateRangeChange} />
              </Form.Item>
            )}
          <Form.Item name="date" noStyle>
            <Select
              showSearch
              placeholder="Select Payment Method"
              optionFilterProp="children"
              // onChange={handleFilterChange}
              // value={searchState?.filterOption || "all"}
              style={{ width: 100 }}
              allowClear
            >
              {["ALL", "BANK", "UPI", "CARD", "CASH"].map((item: any) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>{" "}
          </Form.Item>
          <Button onClick={clearFilters}>Clear Filters</Button>
          </div>
        </Form>
      </PageHeaders>

      {loading ? <HorizontalLineLoader /> : null}

      <div className="screenBox">
        <Card>
          {loading ? (
            <Loading />
          ) : (
            <TransactionDataTable
              data={data}
              page={page}
              pageSize={pageSize}
              total={meta?.itemCount}
              onPageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </div>
  );
}

export default TransactionScreen;

import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../admin/dashboard";
import OfficeScreen from "../admin/office";
import SubscriptionScreen from "../admin/subscription";
import CreateSubscription from "../admin/subscription/createSubscription";
import SubscriptionDetails from "../admin/subscription/details";
import TransactionScreen from "../admin/transaction";
import TransactionDetails from "../admin/transaction/details";
import UserScreen from "../admin/userScreen";
import CreateBooking from "../admin/userScreen/createUser";
import UserDetails from "../admin/userScreen/details";
import LayoutHeader from "./layoutHeader";
import SideBar from "./sideBar";
import "./styles.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import NoData from "../admin/components/noData";
function Routing() {
  const [collapse, setCollapse] = useState(false);
  const User = useSelector((state: any) => state?.User);

  const isOfficeUser = !!User?.user?.office?.id;

  return (
    <Layout>
      <Layout.Sider
        width={collapse ? 70 : 200}
        style={{
          backgroundColor: "#f7f8fa",
          height: "100vh",
        }}
      >
        <SideBar
          doCollapse={() => {
            setCollapse(!collapse);
          }}
        />
      </Layout.Sider>

      <Layout>
        <LayoutHeader />
        <Layout.Content>
          <div className="dashboard-Layout">
            {isOfficeUser ? (
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="userDetails" element={<UserScreen />} />
                <Route path="officeDetails" element={<OfficeScreen />} />
                <Route path="createUser" element={<CreateBooking />} />
                <Route path="createUser/:id" element={<CreateBooking />} />
                <Route path="userDetails/:id" element={<UserDetails />} />
                <Route
                  path="subscriptionDetails"
                  element={<SubscriptionScreen />}
                />
                <Route
                  path="createSubscription"
                  element={<CreateSubscription />}
                />
                <Route
                  path="createSubscription/:id"
                  element={<CreateSubscription />}
                />
                <Route
                  path="subscriptionDetails/:id"
                  element={<SubscriptionDetails />}
                />
                <Route
                  path="transactionDetails"
                  element={<TransactionScreen />}
                />
                <Route
                  path="transactionDetails/:id"
                  element={<TransactionDetails />}
                />
              </Routes>
            ) : (
              <NoData />
            )}
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default Routing;

import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  message,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import HorizontalLineLoader from "../components/loader";
import SubscriptionDataTable from "./dataTable";

function SubscriptionScreen() {
  const navigate = useNavigate();
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  // const onView = (val: any) => {
  //   navigate(`/Auth/subscriptionDetails/${val}`, { state: { val } });
  // };
  const createOrEditBooking = (booking: any) => {
    navigate(`/Auth/createSubscription`, { state: { booking } });
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [pageSize, setpageSize] = useState<any>(10);

  useEffect(() => {
    getData();
  }, [page, pageSize]);

  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setpageSize(take);
  };

  const getData = async () => {
    try {
      let url = `${API.LIST_SUBSCRIPTIONS}${3}
        ?order=DESC&page=${page}&take=${pageSize}`;
      const response: any = await GET(url, null);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Subscription"} breadcrumb="Subscription">
        <Form form={form}>
        <div className="d-flex flex-column flex-wrap flex-sm-row gap-2">

            <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                allowClear
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>

            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>
            <Form.Item noStyle>
              <Button onClick={() => createOrEditBooking({})} type="primary">
                Create +
              </Button>
            </Form.Item>
          </div>
        </Form>
      </PageHeaders>
      {loading ? <HorizontalLineLoader /> : null}
      <div className="screenBox">
        <Card>
          {loading ? (
            <Loading />
          ) : (
            <SubscriptionDataTable
              data={data}
              page={page || 1}
              current={page || 1}
              pageSize={pageSize}
              onChange={handlePageChange}
              total={meta?.itemCount || 0}
            />
          )}
        </Card>
      </div>
    </div>
  );
}

export default SubscriptionScreen;

import { Button, Card, Form, Image, Input, message } from "antd";
import PageHeaders from "../../components/pageHeaders";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { useState } from "react";
import { checkNullValue } from "../../utils/variable";
import { POST } from "../../utils/apiRequest";
import { API } from "../../config/Api";
import "./styles.scss";

const OfficeScreen = () => {
  const { TextArea } = Input;
  const user = useSelector((state: any) => state?.User?.user);
  const details = user?.office;
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: { more_info: string }) => {
    try {
      setLoading(true);

      const requestPayload = {
        office_id: details?.id,
        info: checkNullValue(values.more_info),
      };

      const response: any = await POST(API.OFFICE_INFO_UPDATE, requestPayload);

      if (response?.status) {
        message.success("Office information updated successfully.")
      } else {
        message.success("Failed to update office information.")
      }
    } catch (error) {
      console.error("Error updating office information:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeaders title="Office Details" breadcrumb="Office Details" />
      <Card title={details?.name || "Office Details"}>
        <Row>
          <Col md={2}>
            <Image
              src={details?.logo || "https://via.placeholder.com/200"}
              height={200}
              alt="Office Logo"
              preview={false}
            />
          </Col>

          <Col md={3}>
            <div>
              {[
                { label: "Name", value: details?.name },
                { label: "Email", value: details?.email },
                { label: "Phone", value: details?.phone },
                {
                  label: "Office Time",
                  value: `${moment(details?.opening_time).format("h:mm A")} - ${moment(details?.closing_time).format("h:mm A")}`,
                },
              ].map((item:any, index) => (
                <div key={index} className="profile-box">
                  <div>{item.label}</div>:
                  <div>{item.value || "N/A"}</div>
                </div>
              ))}
            </div>
          </Col>

          {/* Office More Info Section */}
          <Col md={12}>
            <div>
              <h4>More Info</h4>
              <p>
                This information will be shown in all notifications and
                messages.
              </p>
              <Form
                onFinish={onFinish}
                initialValues={{ more_info: details?.more_info }}
              >
                <Form.Item
                  name="more_info"
                  rules={[
                    {
                      required: true,
                      message: "Please provide additional information.",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Enter additional information about the office"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default OfficeScreen;

import { message } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaTags } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { useSelector } from "react-redux";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import Cards from "./component/cards";
import ExpiredUser from "./component/expiredUser";
import ExpiringUser from "./component/expirinUser";
import GraphChart from "./component/graph";
import "./styles.scss";

function Dashboard() {
  const User = useSelector((state: any) => state?.User?.user);
  const id = User?.office?.id;
  const [userCount, setUserCount] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [office, setOffice] = useState<any>({});
  const [subCount, setSubCount] = useState<any>();
  const [usersCount, setUsersCount] = useState<any>();
  const [transaction, setTransaction] = useState<any>();
  const [expiringUsers, setExpiringUsers] = useState<[]>();
  const [expiredUsers, setExpiredUsers] = useState<[]>();
  const [birthdays, setBirthdays] = useState<any[]>();
  const [chartData, setChartData] = useState<any>([]);

  const getOfficeInfo = async () => {
    try {
      let url = `${API.GET_OFFICE_INFO + id}`;
      let response: any = await GET(url, null);
      if (response?.status) {
        setOffice(response?.data?.office);
        setUsersCount(response?.data?.users);
        setSubCount(response?.data?.subscription);
        setTransaction(response?.data?.transaction);
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getExpiringUsers = async () => {
    try {
      let api = `${API.EXPIRE_SOON_USERS + id}?order=DESC&page=1&take=50`;
      let expired: any = await GET(api, {});
      if (expired?.status) {
        setExpiringUsers(expired.data);
        setLoading(false);
      } else {
        setUserCount([]);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getExpiredUsers = async () => {
    try {
      let api = `${API.EXPIRED_USERS}${id}?order=DESC&page=1&take=50`;
      let expired: any = await GET(api, {});
      if (expired?.status) {
        setExpiredUsers(expired.data);
        setLoading(false);
      } else {
        setUserCount([]);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getBirthdayUsers = async () => {
    try {
      let api = `${API.BIRTHDAY_USERS}${id}?order=DESC&page=1&take=50`;
      let expired: any = await GET(api, {});
      if (expired?.status) {
        setBirthdays(expired.data);
        setLoading(false);
      } else {
        setBirthdays([]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChartData = async () => {
    try {
      let api = `${API.SUBCRIPTION_ALL}${id}`;
      const response: any = await GET(api, null);
      if (response?.status) {
        let res = response?.data;
        if (res.length) {
          let arr: any = [];
          response?.data.map((item: any, index: number) => {
            let obj = {
              textColor: "#525252",
              value: Number(item?.userCount),
              label: item?.name,
              labelTextStyle: {
                marginTop: 10,
                transform: [{ rotate: "-45deg" }],
                color: "#525252",
                width: 55,
                height: 20,
              },
            };
            arr = [obj, ...arr];
          });
          setChartData(arr);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.error(response?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const sortedBarData = chartData
    ?.slice()
    ?.sort((a: any, b: any) => b.value - a.value);

  useEffect(() => {
    getOfficeInfo();
    getBirthdayUsers();
    getExpiredUsers();
    getExpiringUsers();
    getChartData();
  }, []);

  const cardItems = [
    {
      icon: <PiUsersThreeDuotone />,
      label: "Total Users",
      value: usersCount || 0,
      navigate:"userDetails"
    },
    {
      icon: <FaTags />,
      label: "Total Subscription",
      value: subCount || 0,
      navigate:"subscriptionDetails"

    },
    {
      icon: <GrTransaction />,
      label: "Total Transaction",
      value: transaction?.count || 0,
      navigate:"transactionDetails"

    },
    {
      icon: <GiMoneyStack />,
      label: "Total Amount",
      value: transaction?.rows[0]?.paid || 0,
      navigate:"transactionDetails"

    },
  ];

  return (
    <Container>
      <br />
      <Row>
        {cardItems?.map((card, idx) => (
          <Col md={3}  key={idx}>
            <Cards icon={card?.icon} label={card?.label} value={card?.value}  navigate={card.navigate}/>
          </Col>
        ))}
      </Row>
      <br />
      <Row>
        <Col md={7} >
          <div className="dashboard-Txt1">GYM GRAPH</div>
          <div className="Dashboard-graph">
            <br />
            <GraphChart data={sortedBarData} />
          </div>
        </Col>
        <Col md={5}>
          <ExpiringUser data={expiringUsers} />
          <ExpiredUser data={expiredUsers} />
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;

import { Button, Card, DatePicker, Form, Input, notification } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import "./style.scss";

function CreateSubscription() {
  const location = useLocation();
  const booking = location.state?.booking;
  const navigate = useNavigate();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      {contextHolder}
      <PageHeaders
        title={booking?.id ? "Update Subscription" : "Create Subscription"}
        backButton={true}
      />
      <div className="screenBox">
        <Card>
          <Form autoComplete="off" layout="vertical">
            <Row>
              <Col md={6}>
                <label className="formLabel">User Name</label>
                <Form.Item
                  name={"userName"}
                  rules={[
                    {
                      required: true,
                      message: "Please input the field",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <label className="formLabel">Phone No</label>
                <Form.Item
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      message: "Please input the field",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <label className="formLabel">E-mail</label>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Please input the field",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <label className="formLabel">Address</label>
                <Form.Item name={"address"}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={6}>
                <label className="formLabel">State</label>
                <Form.Item name={"state"}>
                  <Input />
                </Form.Item>
                <label className="formLabel">District</label>
                <Form.Item name={"district"}>
                  <Input />
                </Form.Item>
                <label className="formLabel">Pincode</label>
                <Form.Item name={"pincode"}>
                  <Input />
                </Form.Item>
                <label className="formLabel">Booking Date</label>
                <Form.Item name={"bookingDate"}>
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row>
              <Col sm={4}></Col>
              <Col sm={4}>
                <Button size="large" danger block onClick={() => navigate(-1)}>
                  Close
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {booking?.id ? "Update" : "Submit"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default CreateSubscription;

export const API = {
  BASE_URL: "https://server.bairuhatech.com/dgymbook/",
  LOGIN: "user_auth/login",
  LIST_OFFICE: "office/all",
  ADD_OFFICE: "office/create",
  UPDATE_OFFICE: "office/update",
  GET_BY_OFFICE: "office/",
  GET_OFFICE_INFO: "office/office_info/",
  SEARCH_OFFICE: "office/search_office",

  CONTACT_ALL: "contact/all",
  CONTACT_CREATE: "contact/create",

  LIST_TRANSACTIONS: "transactions/all_transactions", //?order=ASC&page=1&take=10
  LIST_SUBSCRIPTIONS: "subscriptions/by_office/", // by office id

  USERS_ALL: "users/all",
  USERS_LIST: "users/list/",
  USERS_DELETE: "users/delete/",
  USER_ADD: "users/create", // create USER or TRAINER
  ALL_USERS: "users/getAll", // for export Excel
  USER_DETAILS: 'users/details/', //get user
  USER_BLOCK: 'users/block',

  LOGGED_USERS: "log/byfilter", //?order=ASC&page=1&take=10
  LOGGED_USER_DETAILS: "log/userDetails", //?order=ASC&page=1&take=10

  GET_INVOICE: "transactions/findOne/",
  ALL_TRANS_BY_OFFICE: "transactions/export_excel/",

  PDF_GENERATE_URL: "https://pdf.taxgoglobal.com/getPdf",

  LIST_TRAINERS: "users/list_trainers/",
  TRAINER_UPDATE: "users/update/",
  BLOCK_TRAINER: "users/block/",

  BIRTHDAY_USERS: 'dashboard/birthday_today/', //GET
  EXPIRED_USERS: 'dashboard/expired_users/', // GET ?order=DESC&page=1&take=50
  // EXPIRING_USERS: 'dashboard/expiring_users/', // GET ?order=DESC&page=1&take=50
  EXPIRE_SOON_USERS: 'dashboard/will_expire_soon/', // GET ?order=DESC&page=1&take=50
  USER_COUNT: 'dashboard/count/', //GET
  SUBCRIPTION_ALL: 'subscriptions/all/', // get :-- to get all list of Sub_all
  OFFICE_INFO_UPDATE: 'office/update_more_info',
  USER_FILTER: 'users/byfilter',

  SUBCRIPTION_OFFICE: 'subscriptions/by_office/', // get, delete :-- to get details of subscriptions
  GET_ATTENDANCE: 'users/attendance/',
  TRANSACTIONS: 'transactions/all_transactions', //POST


};

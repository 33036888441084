import { Button, Form, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import Attendance from "./components/attentance";
import Info from "./components/info";
import Transactions from "./components/transaction";
import "./styles.scss"
interface UserDetailsProps {}

const UserDetails: React.FC<UserDetailsProps> = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("Attendance");
  const getUser = async () => {
    try {
      const response: any = await GET(API.USER_DETAILS + id, null);
      if (response?.status) {
        setData(response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  const items = [
    {
      key: "Info",
      label: "Info",
      tab: "Info",
      children: <Info data={data?.subscription}
      endingDate={data?.user?.end_date}
      user={data?.user}/>,
    },

    {
      key: "Transactions",
      label: "Transactions",
      tab: "Transactions",
      children: <Transactions data={data} id={id}/>,
    },
    {
      key: "Attendance",
      label: "Attendance",
      tab: "Attendance",
      children: <Attendance data={data} />,
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
    console.log("Tab changed to:", key);
  };

  return (
    <div>
      <PageHeaders title={"User Details"} breadcrumb={"User Details"}>
        <Form layout="inline">
          <Form.Item>
            <Button>Edit</Button>
          </Form.Item>
          <Form.Item>
            <Button>Block</Button>
          </Form.Item>
        </Form>
      </PageHeaders>
      <Tabs activeKey={activeTab} onChange={onChange} items={items} />
    </div>
  );
};

export default UserDetails;

import { Button, Card } from "antd";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlineSubtitles } from "react-icons/md";

const Info = (props: any) => {
  return (
    <div>
      <Card title="Subscription Summary" className="col-6">
        <div className="transaction-box2">
          <div>
            <MdOutlineSubtitles size={20} />
            <span> Subscription {props?.data?.name}</span>
          </div>
          <div>
            {props?.data?.price} -<span> {props?.data?.time} hrs / day</span>
          </div>
        </div>
        <div className="transaction-box2">
          <div>
            <CiCalendarDate size={20} />
            &nbsp;
            <span>
              Ending Date : {moment(props.endingDate).format("DD/MM/YYYY")}
            </span>
          </div>

          <div>
            <Button>Change Plan</Button>
          </div>
        </div>
      </Card>
      <br />
      <Card className="col-6">
        <div className="d-flex gap-4">
          <Button className="text-success">Add Fingerprint</Button>  
          <Button className="text-warning">Pause Subscription</Button>
          <Button className="text-danger">Remove Subscription</Button>
        </div>
      </Card>
      <br />
      <Card title="Personal Details">
        <Row>
          <Col md={6}>
            <div>
              <div> Email : {props?.user?.email}</div>
              <div> Primary Mobile : {props?.user?.mobile_number}</div>
              <div> Secondary Mobile : {props?.user?.mobile_number2}</div>
              <div> Secondary Mobile : {props?.user?.mobile_number2}</div>
              <div>
                Date of Birth :
                {moment(props?.user?.birth_date).format("MMMM Do, YYYY")}
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <div> Blood Group : {props?.user?.blood}</div>
              <div> Gender : {props?.user?.gender}</div>
              <div> Address : {props?.user?.place}</div>
              <div> Height : {props?.user?.height}</div>
              <div> Weight : {props?.user?.weight}</div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Info;

import "./styles.scss";
import loading from "../../assets/images/gym.gif";

const Loading = () => {
  return (
    <div className="Loading-box">
      <img src={loading} />
    </div>
  );
};

export default Loading;

import { Calendar, Card, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { API } from "../../../config/Api";
import { POST } from "../../../utils/apiRequest";
import "../styles.scss";

const Attendance = (props: any) => {
  const [attendance, setAttendance] = useState<any[]>();
  const [markedDates, setMarkedDates] = useState<any>({});
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<number>(12); 
  const [selectedYear, setSelectedYear] = useState<number>(moment().year()); 

  useEffect(() => {
    if (props?.data?.user?.admisson_id) {
      getAttendance(selectedMonth, selectedYear);
    }
  }, [props?.data, selectedMonth, selectedYear]); 

  const getAttendance = async (month: number, year: number) => {
    try {
      setLoading(true);
      const URL = `${API.GET_ATTENDANCE}`;
      const body = {
        id: props?.data?.user?.admisson_id,
        month,
        year,
      };
      const respo: any = await POST(URL, body);

      if (respo?.status) {
        const data = respo?.data || [];
        setAttendance(data);
        markAttendanceDates(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const markAttendanceDates: any = (data: any) => {
    const dates: any = {};
    data?.forEach((item: any) => {
      const date = item?.LogDate?.split("T")[0];
      dates[date] = true;
    });
    setMarkedDates(dates);
  };

  const onSelectDate: any = (date: moment.Moment) => {
    const selectedDateStr = date?.format("YYYY-MM-DD");
    const filteredLogs: any = attendance?.filter(
      (item: any) => item?.LogDate?.split("T")[0] === selectedDateStr
    );
    setLogs(filteredLogs);
  };

  const dateFullCellRender: any = (date: moment.Moment) => {
    const formattedDate = date?.format("YYYY-MM-DD");
    if (markedDates[formattedDate]) {
      return (
        <div className="attentance-box1">
          <div
            className="attentance-box2"
            style={{
              backgroundColor: "#2eb051",
              width: "30px",
              fontWeight: "bold",
              color: "#fff",
              height: "30px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            {date?.date()}
          </div>
        </div>
      );
    }

    return (
      <div style={{ padding: "10px", textAlign: "center" }}>{date.date()}</div>
    );
  };

  const onPanelChange: any = (value: moment.Moment) => {
    const month = value.month() + 1;
    const year = value.year();
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  if (!props?.data?.user?.admisson_id) {
    return <Spin spinning={true}>Loading attendance data...</Spin>;
  }

  return (
    <Card>
      <Spin spinning={loading}>
        <Calendar
          dateFullCellRender={dateFullCellRender}
          onSelect={onSelectDate}
          onPanelChange={onPanelChange}
          fullscreen={false}
        />
      </Spin>
    </Card>
  );
};

export default Attendance;
